var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 1 || !_vm.language ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 2 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 3 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "comContentRight companyInfo",
    class: _vm.language == 2 ? "comContentRightEn" : ""
  }, [_vm.language == 1 ? _c("h3", [_vm._v("公 司 资 料")]) : _vm._e(), _vm.language == 2 ? _c("h3", [_vm._v("Corporate Information")]) : _vm._e(), _vm.language == 3 ? _c("h3", [_vm._v("公 司 資 料")]) : _vm._e(), _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "注册办事处" : _vm.language == 2 ? "Registered Office" : "註冊辦事處"))]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zcbsc)
    }
  })]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "中国总办事处及主要营业地点" : _vm.language == 2 ? "Head Office and Principal Place of Business in the PRC" : "中國總辦事處及主要營業地點"))]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zgzbscyedd)
    }
  })]), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "香港主要营业地点" : _vm.language == 2 ? "Principal Place of Business in Hong Kong" : ""))]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.xgzyydd)
    }
  })]), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "联席公司秘书" : _vm.language == 2 ? "Joint Company Secretary" : "聯席公司秘書"))]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.lianxigongshi)
    }
  })]), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "授权代表" : _vm.language == 2 ? "Authorized Representatives" : "授權代表"))]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.shouquandaibiao)
    }
  })])], 1)], 1)]), _c("div", {
    staticClass: "comContentRight contact",
    class: _vm.language == 2 ? "comContentRightEn" : ""
  }, [_vm.language == 1 ? _c("h3", [_vm._v("联 系 方 式")]) : _vm._e(), _vm.language == 2 ? _c("h3", [_vm._v("Contact Information")]) : _vm._e(), _vm.language == 3 ? _c("h3", [_vm._v("聯 繫 方 式")]) : _vm._e(), _vm.language == 1 ? _c("ul", [_vm._m(0), _vm._m(1), _vm._m(2)]) : _vm._e(), _vm.language == 2 ? _c("ul", [_vm._m(3), _vm._m(4), _vm._m(5)]) : _vm._e(), _vm.language == 3 ? _c("ul", [_vm._m(6), _vm._m(7), _vm._m(8)]) : _vm._e()])])])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/address.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("地址")]), _c("p", {
    staticClass: "dzDetail"
  }, [_vm._v("中国浙江杭州滨江区 "), _c("br"), _vm._v(" 江二路400号T1幢13层")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/tel.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("电话")]), _c("p", [_vm._v("电话：0571-81107210")]), _c("p", [_vm._v("传真：0571-86430935")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/yj.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("电子邮件")]), _c("p", [_vm._v("IR@nhbio.com.cn")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/address.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("Address")]), _c("p", {
    staticClass: "dzDetail"
  }, [_vm._v("13/F, T1 Building, "), _c("br"), _vm._v("400 Jiang’er Road, Binjiang District,"), _c("br"), _vm._v("Hangzhou, Zhejiang, PRC.")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/tel.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("Tel")]), _c("p", [_vm._v("Tel：0571-81107210")]), _c("p", [_vm._v("Fax：0571-86430935")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/yj.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("Email")]), _c("p", [_vm._v("IR@nhbio.com.cn")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/address.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("地址")]), _c("p", {
    staticClass: "dzDetail"
  }, [_vm._v("中國浙江杭州濱江區 "), _c("br"), _vm._v(" 江二路400號T1幢13層")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/tel.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("電話")]), _c("p", [_vm._v("電話：0571-81107210")]), _c("p", [_vm._v("傳真：0571-86430935")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("img", {
    attrs: {
      src: require("../assets/yj.png")
    }
  }), _c("p", {
    staticClass: "dtitle"
  }, [_vm._v("電子郵件")]), _c("p", [_vm._v("IR@nhbio.com.cn")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };